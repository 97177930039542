/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import 'quill/dist/quill.snow.css';

/* n8n */
@import 'node_modules/@n8n/chat/dist/style.css';
